.image-container{
    columns: 4;
    column-gap: 20px;
}
.image-container .image-box{
    width: 100%;
    margin-bottom: 20px;
    break-inside: avoid;
    display: flex;
    justify-content: center;
}
.image-container .image-box img{
    max-width: 100%;
    border-radius: 15px;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .image-container{
        columns: 3;
    }
}
@media (max-width: 768px) {
    .image-container{
        columns: 3;
    }
}
@media (max-width: 480px) {
    .image-container{
        columns: 2;
    }
}