.flipbook_container {
  overflow: hidden !important;
  width: 50vw;
  /* height: 60vh; */
  /* padding: 50px; */
}

.demoPage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  /* border: 1px solid #ccc; */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.demoPage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}

.flip_buttons {
  margin-top: 50px;
}

.bg-paper {
  /* background-color: #f4e9d7; Vintage paper color */
}

.texture-old-book {
  /* background-image: url('https://media.istockphoto.com/id/654922866/photo/old-yellowed-stained-paper-texture.jpg?s=612x612&w=0&k=20&c=uInwuGGMQMw2RKMillvZz5suVDgIcKD7yvrOmoPVt-k='); */
  /* background-image: url('https://img.freepik.com/free-photo/white-paper-texture_1194-2324.jpg?size=626&ext=jpg&ga=GA1.1.162958315.1697884611&semt=ais'); */
  background-repeat: repeat;
  /* Adjust this based on your texture image */
}


@media only screen and (max-width: 600px) {
  .flipbook_container {
    overflow: hidden !important;
    width: 70vw;
    /* height: 60vh; */
    /* padding: 50px; */
  }
}

@media only screen and (max-width: 405px) {
  /* medium smartphones  */
  .flipbook_container {
    overflow: hidden !important;
    width: 80vw;
    /* height: 60vh; */
    /* padding: 50px; */
  }

}

@media only screen and (max-width: 350px) {
  /* Small smartphones  */
  .flipbook_container {
    overflow: hidden !important;
    width: 75vw;
    /* height: 60vh; */
    /* padding: 50px; */
  }
}