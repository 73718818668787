.navbar-logo {
  width: 70px;
}

.navbar-marquee {
  background-color: #60a5fa;
  color: #fff;
  padding: 5px;
  overflow: hidden;
  /* Hide the overflowing content */
}

.navbar-marquee p {
  animation: marquee 25s linear infinite;
  white-space: nowrap;
  /* Prevent text from wrapping to the next line */
}

@-moz-keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee {
  0% {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%)
  }

  100% {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.navbar-drops {
  z-index: 1;
}

@media only screen and (max-width: 768px) {

  /*Tablets [601px -> 1200px]*/
  .navbar-about:hover {
    margin-bottom: 300px;

  }

  .navbar-academic:hover,
  .navbar-admission:hover {
    margin-bottom: 170px;
  }

  .navbar-logo {
    width: 70px;
  }
}

@media only screen and (max-width: 600px) {

  /*Big smartphones [426px -> 600px]*/
  .navbar-about:hover {
    margin-bottom: 300px;

  }

  .navbar-academic:hover,
  .navbar-admission:hover {
    margin-bottom: 170px;
  }

  .navbar-logo {
    /* margin-bottom: 10px; */
    width: 60px;
  }
}

@media only screen and (max-width: 425px) {

  /*Small smartphones [325px -> 425px]*/
  .navbar-about:hover {
    margin-bottom: 300px;

  }

  .navbar-academic:hover,
  .navbar-admission:hover {
    margin-bottom: 170px;
  }
}