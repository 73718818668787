:root {
  --white: #fff;
  --black: #000;
  --dark: #1e1e1e;
  --gray: rgba(1, 1, 1, 0.6);
  --lite: rgba(255, 255, 255, 0.6);
  /* --primary: #002347; */
  --primary: #60a5fa;
  --secondary: #4ade80;
}

/***************************
              DEFAULT
  ****************************/


.footer-new>fieldset {
  border: 0;
}

.title {
  color: var(--primary);
}

.flex,
.fixed_flex {
  display: flex;
}

.flex-content {
  width: 100%;
  position: relative;
}

.padding_1x {
  padding: 1rem;
}

.padding_2x {
  padding: 2rem;
}

.padding_3x {
  padding: 3rem;
}

.padding_4x {
  padding: 4rem;
}

.footer-new>.btn {
  padding: 0.8rem 2rem;
  border-radius: 5px;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

.btn_1 {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--secondary);
}

.btn_1:hover {
  background-color: transparent;
  color: var(--primary);
}

.btn_2 {
  border: 1px solid var(--secondary);
  background-color: var(--secondary);
  color: var(--dark);
}

.btn_2:hover {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  color: var(--secondary);
}

@media (max-width: 920px) {
  .footer-new>.flex {
    flex-wrap: wrap;
  }

  .padding_1x,
  .padding_2x,
  .padding_3x,
  .padding_4x {
    padding: 1rem;
  }

  .footer-new>.btn {
    padding: 0.5rem 1rem;
  }


}

/***************************
                 FOOTER
  ****************************/
footer {
  background-color: var(--primary);
  color: var(--dark);
}

footer h3 {
  color: var(--white);
  margin-bottom: 1.5rem;
}

footer a {
  color: var(--dark);
  display: block;
  margin: 15px 0;
}

footer a:hover {
  color: var(--white);
}

footer fieldset {
  padding: 0;
}

footer fieldset input {
  background-color: #334f6c;
  border: 0;
  color: var(--dark);
  padding: 1rem;
}

footer fieldset .btn {
  border-radius: 0;
  border: 0;
}

footer fieldset .btn_2:hover {
  background-color: var(--secondary);
  border: 0;
  color: var(--primary);
}

footer .flex:last-child {
  align-items: center;
}

footer .flex:last-child .flex-content:last-child {
  text-align: right;
}

footer .flex:last-child p {
  color: var(--white);
}

footer .flex:last-child a {
  width: 40px;
  display: inline-block;
  background-color: #334f6c;
  color: var(--white);
  padding: 0.5rem;
  margin-right: 3px;
  text-align: center;
}

footer .flex:last-child a:hover {
  background-color: var(--gray);
  color: var(--secondary);
}

.social-media-box {
  background-image: url('../../assets//img/school/social-bg.PNG');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1100px) {
  footer .flex:first-child {
    flex-wrap: wrap;
  }

  footer .flex:first-child .flex-content {
    flex: 1 1 40%;
  }
}

@media (max-width: 920px) {
  footer .flex:last-child .flex-content:last-child {
    text-align: left;
  }
}

@media (max-width: 320px) {
  footer .flex:first-child .flex-content {
    flex: 1 1 100%;
  }
  .fixed_flex {
    display: block;
  }
  .fixed_flex>input {
    width: 80%;
  }
}

